<template>
    <div class="page">
        <div class="hzynav w-1350">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{path: '/'}">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{path: '/wmyxgj?wid=4'}">外贸营销平台工具</el-breadcrumb-item>
                <!-- <el-breadcrumb-item>{{title}}</el-breadcrumb-item> -->
            </el-breadcrumb>
        </div>
        <div class="conter w-1350">
            <div class="tuijian-list"  v-loading="loading">
                <div class="tuijian-item" v-for="(item,index) in ggList" :key="index" @click="goTo('tool_introduce',{id:item.id})">
                    <div class="tuijian-img"><img :src="item.banner_url" alt=""></div>
                    <div class="tuijian-p">{{item.name}}</div>
                </div>              
            </div>

            <div class="pages auto" v-if="ggList.length>0">
                <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :current-page.sync="pagination.page"
                    layout="prev, pager, next, jumper"
                    :page-size="pagination.nate"
                    :total="total"
                >
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import {getplatexts} from '../../../api/index'
    export default {
        data() {
            return {     
                pagination: {
                    page: 1,
                    nate: 20,
                },
                total:1,
                loading:false,
                id:'',
                title:'',
                ggList:[]     
            }
        },
        created() {
            this.id=this.$route.query.id
            if(this.id==4){
                this.title='外贸营销工具'
            }else if(this.id==129){
                this.title='3D动画演示'
            }else if(this.id==130){
                this.title='产业大数据'
            }else if(this.id==131){
                this.title='全景VR'
            }else if(this.id==132){
                this.title='外贸建站'
            }
            this.getplateAds()
        },
        methods: {  
            handleCurrentChange(val) {
                // console.log(val);
                this.getplateAds();
            },
            // 跳转页面
            goTo(url,query) {
                this.$router.push({ 
                    path: url ,
                    query
                });
            },
            // 去除富文本所有标签
            delLabel(input){
                if (input!=null) {
                    return input.replace(/<\/?[^>]*>/g, ""); 
                }
            },
            // 获取外贸营销工具
            getplateAds(){ 
                this.loading=true;
                getplatexts({
                    ...this.pagination,
                    plate_id:this.id,
                    // recom:1,
                }).then((res)=>{
                    // console.log(res)
                    setTimeout(() => {
                        this.loading=false;
                    }, 200);                    
                    if (res.code==200) {
                        this.ggList=res.data.data;
                        this.total=res.data.total
                    }
                })
            },    
        },
    }
</script>

<style lang="less" scoped>
    .pages{
        margin: 50px auto;
        display: flex;
        justify-content: flex-end;
    }
    .page{background: #F4F4F4;overflow: hidden;}
    .hzynav{margin: 35px auto;padding-left: 15px;box-sizing: border-box;}
    .conter{
        margin: auto;margin-bottom:140px;padding:50px 30px;box-sizing: border-box;background:#fff;
        
        .tuijian-list{
            margin-top: 40px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            .tuijian-item{
                width: 18%;        
                background: #FFFFFF;
                box-sizing: border-box;
                box-shadow: 0px 0px 16px 5px rgba(59, 121, 204, 0.18);
                margin-right: 2.5%;
                margin-bottom: 35px;
                border-radius: 15px;
                padding: 35px 0;
                cursor: pointer;
                &:nth-child(5n+0){margin-right: 0;}
                .tuijian-img{
                    width: 70%;height:auto;margin:auto;
                    img{width: auto;height:50px;margin:auto;display: flex;}
                }
                .tuijian-p{ margin: 0 10px;margin-top: 15px;color: #333333;font-size: 20px;font-weight: bold;font-family: Arial;text-align: center;}

            }
        }

    }

</style>